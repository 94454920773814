.event-card {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  transition: transform 0.2s ease;
}

.event-card:hover {
  transform: scale(1.05);
}

.event-card-header {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-card-body {
  padding: 15px;
}

.event-type {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  display: inline-block;
  margin-bottom: 10px;
}

.event-title {
  font-size: 16px;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}

.event-date, .event-location, .event-country, .event-level {
  font-size: 14px;
  margin: 5px 0;
}

.event-country .icon, .event-level .icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.event-card-footer {
  text-align: center;
  padding: 15px;
}

.register-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  transition: background-color 0.2s ease;
}

.register-button:hover {
  background-color: #0056b3;
}
