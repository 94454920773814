/* General Styling */
.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5f5;
    color: #333;
}

  .content {
    text-align: center;
    padding: 20px;
    max-width: 800px; /* Adjust as per your design */
    margin: 0 auto;
  }
  

/* Blog Header */
.header-section {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
}

/* .blog-header-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
} */

.blog-header-text {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    color: #fff;
}

.blog-header-text h1 {
    font-size: 3rem;
    font-weight: bold;
}

.blog-header-text p {
    font-size: 1.2rem;
    font-style: italic;
    margin-top: 10px;
}

/* Blog Content */
.blog-content {
    background: #fff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
    line-height: 1.8;
}

.blog-date {
    font-size: 1rem;
    color: #999;
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
}

.blog-main {
    text-align: center;
}

.blog-main-image {
    width: 100%;
    height: 180px;
    max-width: 900px;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.blog-main h2 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 20px;
    font-weight: bold;
}

.blog-main p {
    font-size: 1.2rem;
    color: #444;
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
}

/* Additional Section */
.additional-section {
    margin-top: 60px;
    padding: 40px;
    background: #fafafa;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.additional-section h3 {
    font-size: 2rem;
    color: #0044cc;
    margin-bottom: 20px;
    font-weight: bold;
}

.additional-section p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 25px;
    line-height: 1.7;
    max-width: 800px;
    text-align: justify;
    margin: 0 auto;
}

/* Bottom Section */
.bottom-section {
    margin-top: 60px;
    padding: 40px;
    background-color: #003366;
    border-radius: 15px;
    color: #fff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.bottom-image {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.bottom-section h3 {
    font-size: 2.2rem;
    margin-bottom: 30px;
    font-weight: bold;
}

.bottom-section ul {
    list-style: none;
    padding: 0;
}

.bottom-section li {
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.bottom-section p {
    font-size: 1rem;
    color: #ccc;
}

/* Media Queries */
@media (max-width: 992px) {
    .blog-main h2 {
        font-size: 2rem;
    }

    .blog-header-text h1 {
        font-size: 2.5rem;
    }

    .additional-section {
        padding: 30px;
    }

    .bottom-section h3 {
        font-size: 1.8rem;
    }
}

@media (max-width: 576px) {
    .blog-main-image {
        width: 100%;
    }

    .bottom-image {
        width: 100px;
    }

    .blog-header-text h1 {
        font-size: 2rem;
    }

    .additional-section h3 {
        font-size: 1.5rem;
    }

    .read-more-button {
        padding: 12px 30px;
        font-size: 1rem;
    }
}
