.upcoming-news-container {
    width: 100%;
  }
  
  .upcoming-news-card {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    transition: transform 0.2s ease;
  }
  
  .upcoming-news-card:hover {
    transform: scale(1.05);
  }
  
  .upcoming-news-card-header {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }
  
  .upcoming-news-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .upcoming-news-card-body {
    padding: 15px;
  }
  
  .upcoming-news-type {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .upcoming-news-title {
    font-size: 16px;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
  }
  
  .upcoming-news-date, 
  .upcoming-news-location, 
  .upcoming-news-country, 
  .upcoming-news-level {
    font-size: 14px;
    margin: 5px 0;
  }
  
 
  
  .upcoming-news-card-footer {
    text-align: center;
    padding: 15px;
  }
  
 
  
  
 
  