.upcoming-event-container {
    width: 100%;
  }
  
  .upcoming-event-card {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    transition: transform 0.2s ease;
  }
  
  .upcoming-event-card:hover {
    transform: scale(1.05);
  }
  
  .upcoming-event-card-header {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }
  
  .upcoming-event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .upcoming-event-card-body {
    padding: 15px;
  }
  
  .upcoming-event-type {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .upcoming-event-title {
    font-size: 16px;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
  }
  
  .upcoming-event-date, 
  .upcoming-event-location, 
  .upcoming-event-country, 
  .upcoming-event-level {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .upcoming-event-country .icon, 
  .upcoming-event-level .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .upcoming-event-card-footer {
    text-align: center;
    padding: 15px;
  }
  
  .upcoming-event-register-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    transition: background-color 0.2s ease;
  }
  
  .upcoming-event-register-button:hover {
    background-color: #0056b3;
  }
  .icon-clock, .icon-location {
    margin-right: 8px;
    color: #333;
  }
  
  .icon-clock {
    color: #007bff;
  }
  
  .icon-location {
    color: #28a745;
  }
  