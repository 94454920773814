/* Grid layout for gallery display */
.gallery-container {
  background-color: #f8f9fa;
  padding: 20px;
}

.gallery-card {
  border: none;
  transition: transform 0.3s ease;
}

.gallery-card:hover {
  transform: scale(1.05);
}

/* Gallery image in the grid (make all images the same size) */
.gallery-image {
  width: 100%;
  height: 200px; /* Fixed height for consistency */
  object-fit: cover; /* Ensure the image fills the container without distortion */
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Full-screen overlay styling */
.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 1, 1, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Full-screen gallery layout */
.full-screen-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}

/* Fullscreen image styling (uniform size and fit) */
.gallery-fullscreen-image {
  width: 300px; /* Fixed width for uniformity */
  height: 300px; /* Fixed height for uniformity */
  object-fit: contain; /* Ensure the image maintains its aspect ratio */
  margin: 10px;
  border-radius: 8px;
}

/* Optional: Remove the extra styling of the first image (cover image) */
.gallery-card-header img:first-of-type {
  object-fit: cover; /* Make sure the first image in the gallery looks consistent */
}
